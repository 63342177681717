import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-20f3e28e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "auto-game-mobile__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isDesktop && !_ctx.bonus)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        onClick: _cache[0] || (_cache[0] = ($event: any) => {
      _ctx.changeChecked();
      _ctx.startAutogame();
    }),
        class: _normalizeClass(["auto-game", {
      '--disabled':
        _ctx.disabled ||
        _ctx.isMaintenance ||
        _ctx.isBetstop ||
        _ctx.isDoublingOn ||
        (_ctx.controlDisabled && !_ctx.autoGame) ||
        _ctx.disconnected ||
        _ctx.promoIsOn,
    }])
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(["auto-game__text", { '--glow': _ctx.autoGame }])
        }, _toDisplayString(_ctx.$t("autoGame")), 3)
      ], 2))
    : (!_ctx.isMaintenance && !_ctx.isBetstop && !_ctx.bonus)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          onClick: _cache[1] || (_cache[1] = ($event: any) => {
      _ctx.changeChecked();
      _ctx.startAutogame();
    }),
          class: _normalizeClass(["auto-game-mobile", {
      '--glow': _ctx.autoGame,
      '--disabled':
        _ctx.disabled ||
        _ctx.isMaintenance ||
        _ctx.isBetstop ||
        _ctx.isDoublingOn ||
        (_ctx.controlDisabled && !_ctx.autoGame) ||
        _ctx.disconnected ||
        _ctx.promoIsOn,
    }])
        }, [
          _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t("autoGame")), 1)
        ], 2))
      : (_ctx.isDesktop && _ctx.bonus)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: _normalizeClass(["auto-game", {
      '--disabled': !_ctx.isBonusGame || _ctx.promoIsOn,
      '--activated': _ctx.isBonusGame,
    }])
          }, [
            _createElementVNode("span", {
              class: _normalizeClass(["auto-game__text", { '--glow': _ctx.isBonusGame }])
            }, _toDisplayString(_ctx.$t("autoGame")), 3)
          ], 2))
        : (!_ctx.isDesktop && _ctx.bonus)
          ? (_openBlock(), _createElementBlock("div", {
              key: 3,
              class: _normalizeClass(["auto-game-mobile", {
      '--disabled': !_ctx.isBonusGame || _ctx.promoIsOn,
      '--activated': _ctx.isBonusGame,
    }])
            }, [
              _createElementVNode("span", {
                class: _normalizeClass(["auto-game-mobile__text", { '--glow': _ctx.isBonusGame }])
              }, _toDisplayString(_ctx.$t("autoGame")), 3)
            ], 2))
          : _createCommentVNode("", true)
}