
import { defineComponent } from "vue";
import { AudioUtils, convert } from "@/utils";
import store from "@/store";
import {
  calcBonusNumbers,
  calcBonusNumbersKhoja,
  calcBonusNumbersKissaho,
  calcBonusNumbersMisr,
  calcBonusNumbersPearl,
  calcBonusNumbersSilkWay,
  calcBonusNumbersPamirLegends,
  calculateGambusakiTenGlow,
  calculateGambusakiTicketGlow,
  calculateKhojaTicketGlow,
  calculateMevahoTicketGlow,
  calcBonusNumbersAstrologer,
} from "@/utils/ticket-calculator";
import { TicketCombination, UpdateTicket } from "@/types/tickets";
import DigitalScoreboard from "@/components/Scoreboards/DigitalScoreboard.vue";
import storeEvents from "@/store-events";
import FruitScoreboard from "@/components/Scoreboards/FruitScoreboard.vue";
import { GameId, MevahoMode } from "@/types/user";
import {
  COEFFICIENT_MULTIPLY_KHOJA,
  TYPICAL_ANIMATION_TIME,
  TYPICAL_ANIMATION_TIME_DIGIT,
} from "@/constants";
import { DrawItem } from "@/types/draws";
import BeetleScoreboard from "@/components/Scoreboards/BeetleScoreboard.vue";
import KhojaScoreboard from "@/components/Scoreboards/KhojaScoreboard.vue";
import PearlScoreboard from "@/components/Scoreboards/PearlScoreboard.vue";
import KissahoScoreboard from "@/components/Scoreboards/KissahoScoreboard.vue";
import SilkWayScoreboard from "@/components/Scoreboards/SilkWayScoreboard.vue";
import MisrScoreboard from "@/components/Scoreboards/MisrScoreboard.vue";
import PamirScoreboard from "@/components/Scoreboards/PamirScoreboard.vue";
import AstroScoreboard from "@/components/Scoreboards/AstroScoreboard.vue";

const ANIMATION_TIME = 2 * 1000;
const ANIMATION_TIME_DELAY = 0.3 * 1000;

export default defineComponent({
  name: "scoreboard-wrapper",
  components: {
    KhojaScoreboard,
    BeetleScoreboard,
    FruitScoreboard,
    DigitalScoreboard,
    KissahoScoreboard,
    PearlScoreboard,
    SilkWayScoreboard,
    MisrScoreboard,
    PamirScoreboard,
    AstroScoreboard,
  },
  data() {
    return {
      updateInterval: undefined as number | undefined,
      animationInterval: undefined as number | undefined,
      animationTimeout: undefined as number | undefined,
      animationDelay: undefined as number | undefined,
      scoreboard:
        store.state.currentTicket && store.state.currentTicket.draw.numbers.length > 0
          ? store.state.currentTicket.draw.numbers
          : new Array(15),
      indexAnimationArray: [] as number[],
      counter: 0,
      isWon: false,
      isBonusInBonus: false,
    };
  },
  beforeMount() {
    storeEvents.on("afterTicketUpdate", this.afterTicketUpdate);
    storeEvents.on("afterErrorTicketUpdate", this.afterErrorTicketUpdate);
    storeEvents.on("createTicket", this.afterCreateTicket);
    storeEvents.on("stopAnimation", this.stopAnimation);
  },
  beforeUnmount() {
    storeEvents.off("afterTicketUpdate", this.afterTicketUpdate);
    storeEvents.off("createTicket", this.afterCreateTicket);
    storeEvents.off("afterErrorTicketUpdate", this.afterErrorTicketUpdate);
    storeEvents.off("stopAnimation", this.stopAnimation);

    if (this.updateInterval) window.clearInterval(this.updateInterval);
    if (this.animationInterval) window.clearInterval(this.animationInterval);
    if (this.animationTimeout) window.clearTimeout(this.animationTimeout);
    if (this.animationDelay) window.clearTimeout(this.animationDelay);
  },
  mounted() {
    if (store.state.uiVersion === "desktop") {
      this.updateScaleForDesktop();
      this.updateInterval = window.setInterval(this.updateScaleForDesktop, 100);
    }
    if (store.state.currentTicket && store.state.currentTicket.status === "won") {
      this.afterTicketUpdate(store.state.currentTicket);
    }
  },
  computed: {
    scoreboardMode(): MevahoMode {
      return store.state.userData.settings.mevaho_mode;
    },
    currentCombination(): TicketCombination | undefined {
      return store.state.createTicket.combination;
    },
    gameId(): GameId {
      return store.state.currentGameId;
    },
    isWidescreen(): boolean {
      return store.state.userData?.settings?.widescreen === "on";
    },
    currentPayout(): number {
      return store.state.currentTicket && store.state.currentTicket.payout
        ? store.state.currentTicket.payout
        : 0;
    },
  },
  methods: {
    updateScaleForDesktop() {
      if (store.state.uiVersion === "desktop") {
        const carpetNice = document.querySelector(".carpet-nice__item") as HTMLElement;
        const ratio = this.isWidescreen ? 1.5 : 1.25;
        const el1 = this.$el as HTMLElement;
        const gp = el1.parentElement!;
        // ограничиваю высоту minimap-container сверху с запасом
        const freeHeightRem = this.isWidescreen ? 1.1 : 12.5;
        const freeHeight = gp.clientHeight - convert(freeHeightRem, "rem", "px");
        const freeWidthRem = this.isWidescreen ? 0.45 : 1.5;
        const freeWidth = gp.clientHeight - convert(freeWidthRem, "rem", "px");
        const freeWidthCarpet = convert(1.1445, "rem", "px");
        // const freeHeight = gp.clientHeight;
        // считаю 1:2 сколько можно дать ширины контейнеру
        const width = Math.min(ratio * freeWidth, gp.clientWidth);
        // и снова пересчитываю высоту с учетом 1:2
        const height = Math.min(freeHeight, gp.clientWidth);
        el1.style.height = height + "px";
        el1.style.width = width + "px";
        if (carpetNice) {
          carpetNice.style.backgroundSize = `${width + freeWidthCarpet}px ${height}px`;
        }
        el1.style.paddingBottom = "";
      }
    },
    isGlow(idx: number): boolean {
      return this.indexAnimationArray.includes(idx);
    },
    goAnimation(array: number[][], payoutLines: number[], isBonus: boolean, ticket: UpdateTicket) {
      const isFreeTicket = ticket.is_free_ticket;
      const hasFreeTickets = ticket.has_free_tickets;
      const coefficient =
        (this.gameId === "loto-khoja" ||
          this.gameId === "loto-pearl" ||
          this.gameId === "loto-silk-way" ||
          this.gameId === "loto-astrologer" ||
          this.gameId === "loto-misr") &&
        ticket.is_free_ticket
          ? COEFFICIENT_MULTIPLY_KHOJA
          : 1;
      this.indexAnimationArray = array[this.counter];
      if (!isBonus && !isFreeTicket && payoutLines[this.counter]) {
        store.commit("setLastPayout", payoutLines[this.counter]);
        store.commit("setStepPayout", payoutLines[this.counter]);
        store.dispatch("playLineWin", array.length > 1 ? "short" : "long");
      } else if (!isBonus && isFreeTicket && payoutLines[this.counter]) {
        store.commit(
          "setLastPayout",
          store.state.ui.lastPayout + payoutLines[this.counter] * coefficient
        );
        store.commit("setStepPayout", payoutLines[this.counter] * coefficient);
        store.dispatch("playLineWin", array.length > 1 ? "short" : "long");
      }
      this.animationInterval = window.setInterval(() => {
        this.counter += 1;
        if (this.counter === array.length) {
          window.clearInterval(this.animationInterval);
          store.commit("setStepPayout", this.currentPayout);
        } else {
          this.indexAnimationArray = [];
          this.animationDelay = window.setTimeout(() => {
            this.indexAnimationArray = array[this.counter];
            if (this.counter + 1 === array.length) {
              store.dispatch("playLineWin", "long");
            }
            if (this.counter + 1 !== array.length) {
              store.dispatch("playLineWin", "short");
            }
            if (!hasFreeTickets && payoutLines[this.counter]) {
              store.commit(
                "setLastPayout",
                store.state.ui.lastPayout + payoutLines[this.counter] * coefficient
              );
              store.commit("setStepPayout", payoutLines[this.counter] * coefficient);
            }
            // console.log("payoutLine: ", payoutLines[this.counter]);
          }, ANIMATION_TIME_DELAY);
        }
      }, ANIMATION_TIME);
    },
    afterTicketUpdate(ticket: UpdateTicket, stopAnim: boolean = false) {
      window.clearInterval(this.animationInterval);
      const payoutByLine = ticket.payout_by_line;
      const payoutLines = payoutByLine.lines_payout.filter((line) => line > 0);
      if (payoutByLine.bonus_payout > 0) payoutLines.push(payoutByLine.bonus_payout);
      this.indexAnimationArray = [];
      this.isWon = false;
      this.counter = 0;
      this.isBonusInBonus = false;
      const animDelay =
        this.scoreboardMode === "digit" ? TYPICAL_ANIMATION_TIME_DIGIT : TYPICAL_ANIMATION_TIME;
      if (
        ticket &&
        ticket.draw &&
        ticket.draw.numbers &&
        ticket.draw.numbers.length > 0 &&
        this.currentCombination &&
        this.gameId
      ) {
        let isBonus = false;
        const numbers = ticket.draw.numbers;
        const tenWon = calculateGambusakiTenGlow(numbers);
        const bonusWon =
          this.gameId === "loto-khoja"
            ? calcBonusNumbersKhoja(numbers)
            : this.gameId === "loto-pearl"
            ? calcBonusNumbersPearl(numbers)
            : this.gameId === "loto-kissaho"
            ? calcBonusNumbersKissaho(numbers)
            : this.gameId === "loto-silk-way"
            ? calcBonusNumbersSilkWay(numbers)
            : this.gameId === "loto-misr"
            ? calcBonusNumbersMisr(numbers)
            : this.gameId === "loto-pamir-legends"
            ? calcBonusNumbersSilkWay(numbers)
            : this.gameId === "loto-astrologer"
            ? calcBonusNumbersAstrologer(numbers)
            : calcBonusNumbers(numbers);
        const isFreeTicket = ticket.is_free_ticket;
        const hasFreeTickets = ticket.has_free_tickets;
        let wonArray: number[][] = [];
        if (this.gameId === "loto-mevaho-5") {
          wonArray = calculateMevahoTicketGlow(numbers, this.currentCombination, this.gameId);
        } else if (this.gameId === "loto-gambusaki-9" && tenWon.length > 0 && isFreeTicket) {
          isBonus = true;
          wonArray = tenWon;
        } else if (this.gameId === "loto-gambusaki-gold-9" && bonusWon.length > 0 && isFreeTicket) {
          store.commit("rollAnimBonusArray", bonusWon);
          wonArray = bonusWon;
          this.isBonusInBonus = true;
          isBonus = true;
        } else if (this.gameId === "loto-gambusaki-9" || this.gameId === "loto-gambusaki-gold-9") {
          wonArray = calculateGambusakiTicketGlow(numbers, this.currentCombination, this.gameId);
        } else if (
          (this.gameId === "loto-khoja" ||
            this.gameId === "loto-kissaho" ||
            this.gameId === "loto-silk-way" ||
            this.gameId === "loto-pamir-legends" ||
            this.gameId === "loto-pearl" ||
            this.gameId === "loto-astrologer" ||
            this.gameId === "loto-misr") &&
          ticket.has_free_tickets &&
          isFreeTicket
        ) {
          wonArray = bonusWon;
          this.isBonusInBonus = true;
          isBonus = true;
        } else if (
          this.gameId === "loto-khoja" ||
          this.gameId === "loto-kissaho" ||
          this.gameId === "loto-silk-way" ||
          this.gameId === "loto-pearl" ||
          this.gameId === "loto-astrologer" ||
          this.gameId === "loto-misr" ||
          this.gameId === "loto-pamir-legends"
        ) {
          wonArray = calculateKhojaTicketGlow(numbers, this.currentCombination, this.gameId);
        }
        if (wonArray.length > 0 && !stopAnim) {
          this.animationTimeout = window.setTimeout(() => {
            this.isWon = true;
            this.goAnimation(wonArray, payoutLines, isBonus, ticket);
          }, animDelay);
        }
        this.scoreboard = numbers;
      } else {
        this.scoreboard = new Array(15);
        this.counter = 0;
        this.isWon = false;
      }
    },
    afterErrorTicketUpdate(data: DrawItem) {
      this.scoreboard = data.numbers;
      this.isWon = false;
    },
    afterCreateTicket() {
      if (this.scoreboardMode === "digit") {
        this.scoreboard = new Array(15);
        this.counter = 0;
        this.isWon = false;
        window.clearInterval(this.animationInterval);
      }
    },
    stopAnimation() {
      window.clearInterval(this.animationInterval);
      window.clearTimeout(this.animationTimeout);
      window.clearTimeout(this.animationDelay);
      this.isWon = false;
      this.counter = 0;
    },
  },
  watch: {
    scoreboardMode() {
      this.updateScaleForDesktop();
    },
  },
});
